import { Content } from '@prismicio/client';
import { PrismicRichText, SliceComponentProps } from '@prismicio/react';
import Container from '@/components/Unknown/Container';
import { PrismicNextImage } from '@prismicio/next';

/**
 * Props for `BoxedFeature`.
 */
export type BoxedFeatureProps = SliceComponentProps<Content.BoxedFeatureSlice>;

/**
 * Component for "BoxedFeature" Slices.
 */
const BoxedFeature = ({ slice }: BoxedFeatureProps): JSX.Element => {
  return (
    <section
      data-slice-type={slice.slice_type}
      data-slice-variation={slice.variation}
      className="text-black ~/md:~py-16/20 md:~md/lg:~py-20/[4.5rem] lg:~lg:~py-[4.5rem]/[5.625rem]"
    >
      <Container>
        <article className="flex flex-col items-center gap-10 rounded-[25px] bg-paper py-8">
          <PrismicNextImage
            field={slice.primary.icon}
            fallbackAlt=""
            className="aspect-video max-w-[146px] object-contain"
          />
          <div className="o-6">
            <p className="text-pretty text-center font-bold ~px-6/4 ~lg:~text-arc-xl/arc-2xl">
              {slice.primary.heading}
            </p>
            <PrismicRichText
              field={slice.primary.description}
              components={{
                paragraph: ({ children }) => (
                  <p className="mx-auto max-w-prose text-pretty text-center text-base font-normal leading-snug ~px-6/4 sm:~lg:~text-base/lg [&>a]:text-brand-color-main">
                    {children}
                  </p>
                ),
              }}
            />
          </div>
        </article>
      </Container>
    </section>
  );
};

export default BoxedFeature;
